$primary: #9ba28d;
$primaryhover: #9ba28d;

$secondary: #ffffff;

$tertiary: #7e8990;
$tertiaryhover: #7e8990;

$quaternary: #e5e7e9;
$quaternaryhover: #9ca2a8;
$quaternary_light: #e5e9eb;

$gold: $primary;

$font-family-base: 'SourceSansPro', sans-serif;
$headings-font-family: 'BebasNeue', sans-serif;
$font-size-base: 1rem;
$px-base: $font-size-base/16;

$grid-columns: 12;
$grid-gutter-width: 5px;

$border-radius:                     0px;
$card-border-radius:                0px;
$card-spacer-y:                     5px;
$card-spacer-x:                     5px;
$card-border-width:                 0px;
$card-bg:                           #ffffff;

$input-btn-padding-y:               0.175rem;

//$font-size-base: 1rem;
//$px-base: $font-size-base/17;
//$line-height-base: 1.411764706;

//$font-primary: #233240;
//$font-secondary: $brand-red;

//$primary: $brand-red;
//$secondary: $brand-primary;

//$body-color: $brand-primary;

//$line-height-base: 1.7;

//$headings-font-family: 'Quicksand', sans-serif;
//$headings-font-weight: 'normal';
//$headings-color: $brand-primary;

//$std-margin: 40px;

//$breadcrumb-bg: transparent;
//$breadcrumb-padding-x: 0;
//$breadcrumb-padding-y: 0.5rem;
//$breadcrumb-margin-bottom: 0;
//$breadcrumb-divider: '•';

//$input-bg: $brand-gray;
//$input-border-radius: 0;
//$input-border-width: 0;
//$btn-border-radius: 0;
//$tooltip-max-width: 20rem;