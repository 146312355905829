/* -----------------------------------------
   SUCHE & SUCHSEITE
----------------------------------------- */

#search {
  width: 250px;
  margin: auto;
  margin-right: 20px;

  #searchbar{
    background-color: $secondary;
    height: 39px;
  }
  form{
    input {
      border-radius: 0;
      background: transparent;
      border: none;
      color: $primary;
      font-size: 18*$px-base;
    }
    input:focus-visible {
      border: none !important;
      outline: none !important;
    }

    input::placeholder {
      color: $primary;
    }
  }
}

#searchbar{
  display: flex;
  justify-content: end;
  align-items: center;

  #searchtext{
    width: 200px;
    font-size: 24*$px-base;
  }
  #searchsubmit{
    background-image: url('images/icons/header/suche.svg');
    width: 30px;
    height: 30px;
    border-bottom: none;
    margin-top: 10px;
    margin-right: 10px;
  }
}