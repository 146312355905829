@font-face {
  font-family: 'SourceSansPro';
  src: url('https://tourismus.eifel.info/wp-content/themes/tourismusnetzwerk40/fonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('https://tourismus.eifel.info/wp-content/themes/tourismusnetzwerk40/fonts/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'BebasNeue';
  src: url('https://tourismus.eifel.info/wp-content/themes/tourismusnetzwerk40/fonts/BebasNeue-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}