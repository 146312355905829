
/* -----------------------------------------
   POST PAGE (single.php)
----------------------------------------- */

.greyback{
  background: $quaternary;
  box-shadow: inset 0px 110px 0 0 #ffffff;
  padding-bottom: 40px;
}

.boxauthor{
  padding: 30px;

  a{
    color: $primary;
  }
}


.authorname{
  text-transform: uppercase;
  color: #000000 !important;

  span, a{
    font-weight: bold;
    color: #000000 !important;
  }
}

html body .singlepost .categories{
  text-transform: uppercase;
  color: $tertiary !important;
  padding-bottom: 5px !important;
  span{
    color: #000000 !important;
  }
  a{
    font-weight: bold !important;
    color: $tertiary;
  }
}

hr {
  border: none !important;
  border-bottom: solid 1px $primary !important;
}

/* -----------------------------------------
   RELATED POSTS
----------------------------------------- */

.related_post_title{
  display: none;
}

.related_post{
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: stretch;
}

.related_post li {
  background-color: $primary;
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 5px;
  margin-bottom: 20px;

  * {
    color: #ffffff;
    text-align: left;
  }

  img {
    width: 100%;
    height: auto;
  }

  small{
    padding: 5px 20px !important;
    text-transform: uppercase;
  }

  .wp_rp_title {
    font-size: 16*$px-base;
    margin: 0;
    padding: 5px 20px !important;
    margin-bottom: 20px;
  }
}



@include media-breakpoint-down(sm){
  .related_post{
    display: block;

    li{
      width: 100%;
    }
  }
}

/* -----------------------------------------
   Copyrigth
----------------------------------------- */

.single-post, .page{
  .thumbnailboxwithquelle{
    max-width: 100%;
    width: inherit;
  }
}

.single-post img.size-full{
  width: 100%;
  height: auto;
}

.single-post .titlebalken .thumbnailboxwithquelle{
  max-width: inherit !important;
  width: 334px !important;
  background-color: transparent !important;
}

.postpiccontainer{
  text-align: center;
  position: relative;
  background-color: $quaternary;
}

.postgridview{
  .thumbnailboxwithquelle{
    padding-top: 75%;
    img{
      position: absolute;
    }
  }
  @include media-breakpoint-down(sm){
    padding-top: 0px !important;
  }
}

.isc_image_list_title{
  font-weight: bold;
}
.isc-source-text {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: rgb(255, 255, 255) !important;
}

.slick3er{
  .thumbnailboxwithquelle {
    .isc-source-button{
      right: 30px;
    }
    .isc-source-text {
      left: 15px;
      width: calc(100% - 90px);
    }
  }
}
.thumbnailboxwithquelle {
  position: relative;
  display: inline-block;
  width: 100%;

  p {
    margin: 0 !important;
  }
  .isc-source{
   position: static;
    display: inline;
    span{
      display: none !important;
    }
  }

  .isc-source-button{
    position: absolute;
    right: 10px;
    top: 0px;
    background-color: #000000;
    border-radius: $border-radius;
    opacity: 0.6;
    font-weight: bold;
    padding: 10px;
    z-index:50;
    font-size: 18*$px-base;
    a{
      color: #ffffff;
    }
  }
  .isc-source-text{
    display: none;
  }
  .isc-source-text {
    position: absolute;
    font-size: 0.9em;
    color: rgb(255, 255, 255);
    padding: 0px 0.15em;
    text-shadow: none;
    left: 5px;
    top: 5px;
    z-index: 9999;
    display: none;
    width: calc(100% - 50px);
  }

  img{
    max-width: 100%;
    width: 100%;
    border-radius: $border-radius;
    top: 0;
    z-index: 1;

    &.noscale{
      width: auto !important;
    }
  }
}



.fulldropdownshow{
  width: 100% !important;
}

.printericon{
  padding-top: 15px;
  svg{
    width: 45px;
  }
}

.ccchildpages{
  &.threecol{
    .ccchildpage{
      min-height: 290px;
    }
  }
  &.twocol{
    .ccchildpage{
      min-height: 390px;
    }
  }
  .ccchildpage{
    background-color: #8e1c3f !important;
    border-radius: $border-radius !important;
    padding: 0px !important;
    display: flex;
    flex-direction: column;


    *{
      color: #ffffff !important;
    }

    .cc-child-pages-thumb{
      order: -1;
      margin: 0px !important;
      width: 100% !important;
    }

    h3, .ccpages_excerpt, .ccpages_more{
      padding: 0px 15px;
    }

    h3{
      padding-top: 8px;
    }
    .ccpages_more a{
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      border: 1px solid transparent;
      padding: 0.175rem 0.75rem;
      font-size: 1rem;
      line-height: 1.5;
      border-color: #ffffff;
      display: inline-block;
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
      border-radius: $border-radius;
      font-style: normal;
    }
  }
}

a.blocklink:hover{
  text-decoration: none !important;
}
.tnteaser{
  min-height: 505px;
  padding: 20px;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: auto, cover;
  background-position: bottom, center;
  background-repeat: no-repeat;
  margin-bottom: 30px;
  *{
    color: #ffffff;
    text-decoration: none !important;
  }
  .date{
    font-size: 13*$px-base;
    font-weight: bold;
    padding-bottom: 10px;
    display: inline-block;
  }
  h3{
    font-size: 28*$px-base;
    font-weight: normal;
    color: $secondary;
  }
  .buttonbox{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .button{
      display: inline;
      background-color: $primary;
      border-radius: $border-radius;
      padding: 10px 10px 10px 20px;
      align-self: start;
      font-weight: bold;
      font-size: 14*$px-base;
      &:after{
        content: ' ';
        background-image: url('images/icons/pfeil-rechts-white.svg');
        background-size: 12px 12px;
        height: 12px;
        width: 12px;
        margin-left: 10px;
        display: inline-block;
      }
      &:hover{
        background-color: $tertiaryhover;
      }
    }
  }
}

.tnicon{
  text-align: center;
  svg {
    width: 75px;
    height: 75px;
    path{
      fill: $gold;
    }
  }
}
.goldenbackground{
  svg path{
    fill: $tertiary;
  }
}

.tnbutton{
  display: flex;
  a{
    display: inline-block;
    display: inline;
    background-color: $primary;
    border-radius: $border-radius;
    padding: 8px 8px 8px 20px;
    align-self: start;
    font-weight: bold;
    font-size: 14*$px-base;
    color: #ffffff;

    .iconpfeil{
      height: 12px;
      width: 12px;
      margin-left: 10px;
      display: inline-block;
    }
    &:hover{
      text-decoration: none !important;
      background-color: $tertiary;
    }
  }
  a.farbe01{
    background-color: $primary;
    &:hover{
      background-color: $tertiary !important;
    }
  }
  a.farbe02{
    background-color: $tertiary;
    &:hover{
      background-color: $tertiaryhover;
    }
  }
  a.farbe03{
    background-color: $gold;
    &:hover{
      background-color: #af9c71;
    }
  }
  a.weiss{
    background-color: #ffffff;
    color: $primary !important;
    .iconpfeil svg *{
      stroke: $primary !important;
      fill: $primary !important;
    }
  }
  &.right{
    justify-content: flex-end;
  }
  &.center{
    justify-content: center;
  }
  &.left{
    justify-content: start;
  }
}

html body .vc_grid.vc_row.vc_grid-gutter-35px .vc_grid-item{
  padding-right: 45px;
  padding-bottom: 45px;
}

.blogbeitraege{
  .vc_pageable-slide-wrapper{
    display: flex;
    flex-wrap: wrap;
  }
  .vc_grid-item-mini.vc_clearfix,
  .vc_gitem-animated-block,
  .vc_gitem-zone,
  .vc_gitem-zone-a .vc_gitem-zone-mini,
  .vc_gitem-zone-a .vc_gitem-row-position-top,
  {
    height: 100%;
  }
}

.postgridview{
  .marginbottomspacer{
    margin-bottom: 60px;
  }
  .card{
    -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    border-radius: $border-radius;
    height: 100%;
    margin: 20px;
    padding: 10px;
    padding-top: 0px;

    .card-img-top{
      transform: scale(1.15);
      -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      border-radius: $border-radius;
    }

    .thumbnailboxwithquelle .isc-source-button{
      top: -16px;
      bottom: inherit;
    }

    .card-text,.card-text * {

    }
    .card-title *{
      color: $tertiary !important;
      font-weight: normal;
      font-size: 27*$px-base;
    }
    .card-body{
      margin-top: -5px;
    }
    .text-muted{
      font-size: 16*$px-base;
      color: $primary !important;
      font-weight: bold;
    }
    .katbox{
      display: block;
      text-align: right;
      a{
        background-color: $tertiary;
        border-radius: $border-radius;
        color: #ffffff;
        display: inline-block;
        padding: 5px 10px;
        font-size: 12*$px-base;
        text-transform: uppercase;
        min-height: 37px;
        display: inline-flex;
        align-items: end;
        font-weight: bold;
        text-decoration: none;

        &:hover{
          background-color: #9ca2a8 !important;
        }
      }
    }
    .card-footer{
      background-color: #ffffff;
    }
    &.kachelgreenback{
      background-color: $primary !important;
      .text-muted, .card-title *{
        color: #ffffff !important;
      }
      .card-footer, .thumbnailboxwithquelle{
        background-color: $primary !important;
      }
      .tnbutton a{
        background-color: #ffffff !important;
        color: $primary !important;

        &:hover{
          background-color: $primary !important;
          color: #ffffff !important;
          box-shadow: inset 0px 0px 0px 1px #ffffff;
          svg path{
            fill: #ffffff !important;
          }
        }
        svg path{
          fill: $primary !important;
        }
      }
    }
  }
}
.postgridschmall{
  h4{
    font-size: 19*$px-base;
  }
  .vc_gitem-row-position-top{
    .vc_gitem-col{
      min-height: 695px !important;
    }
  }
}
.tnpostgridbox{
  overflow: visible !important;

  .vc_gitem-row-position-top{
    position: relative !important;

    .vc_gitem-col{
      -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      border-radius: $border-radius;
      min-height: 640px;
    }

    .wpb_single_image .vc_figure{
      display: block;
      text-align: center;
    }

    .wpb_single_image img{
        transform: scale(1.15);
      -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
      border-radius: $border-radius;
    }

    .vc_grid-filter {
      text-align: right !important;
      display: block !important;
    }
    .vc_gitem-post-data-source-post_categories{
      margin-bottom: 0px;
      span{
        background-color: $quaternary;
        border-radius: $border-radius;
        color: #ffffff;
        display: inline-block;
        padding: 5px 15px;
        font-size: 14*$px-base;
        text-transform: uppercase;
      }
    }
    .vc_gitem-post-data-source-post_categories > div{
      display: none;
    }

    .vc_gitem-post-data-source-post_categories > div:first-of-type{
      display: block;
    }
    .vc_grid-filter.vc_grid-filter-color-grey>.vc_grid-filter-item:hover{
      background-color: transparent;
    }

  }
  .vc_gitem-row-position-top{
    .wpb_single_image{
      margin-bottom: 35px !important;
    }
    .vc_custom_heading div{
      color: $primary !important;
      font-weight: bold;
    }
    .vc_custom_heading h5{
      color: $tertiary !important;
      font-size: 27*$px-base;
    }
    .vc_gitem-post-data-source-post_excerpt *{
      color: #000000 !important;
      font-weight: normal;
    }
    .vc_btn3{
      display: inline-block;
      display: inline;
      background-color: $primary !important;
      border-radius: $border-radius;
      padding: 5px 10px 5px 20px;
      align-self: start;
      font-weight: bold;
      font-size: 14*$px-base;
      color: #ffffff;

      &:after{
        content: ' ';
        background-image: url('images/icons/pfeil-rechts-white.svg');
        background-size: 12px 12px;
        height: 12px;
        width: 12px;
        margin-left: 10px;
        display: inline-block;
      }
      &:hover{
        text-decoration: none !important;
      }
    }
  }
}

.vc_grid-container .owl-carousel .owl-stage-outer{
  height: inherit !important;
  overflow: visible;
}

html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey>li>a, html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey>li>span,
html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey > li > a, html body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-grey > li > span{
  background-color: $primary !important;
  font-size: 14*$px-base;
  padding: 5px;
}

.katbuttonbox{
  background-color: #ffffff;
  padding: 25px;

  div{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .button{
    display: inline-block;
    background-color: $tertiary !important;
    border-radius: $border-radius;
    padding: 5px 10px 5px 10px;
    margin: 6px 12px;
    align-self: start;
    font-family: $headings-font-family;
    font-weight: normal;
    font-size: 16*$px-base;
    letter-spacing: 2px;
    color: #ffffff;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    box-shadow: 0px 0px 10px -1px rgba(51,51,51,0.5);
    text-decoration: none;

    &:hover{
      //background-color: $primary !important;
    }
  }

  .collapsebutton{
    border-radius: $border-radius;
    display: block;
    text-align: center;
    width: 100%;
    color: $primary;
    padding: 20px;
    background-color: $quaternary_light !important;
    text-decoration: none;
    font-weight: normal;
    .gimmemyarrow{
      stroke: $primary !important;
    }
  }

  @include media-breakpoint-up(sm){
    background-color: $quaternary_light;
    .collapsebutton{
      display: none;
    }
    .collapse{
      display: flex !important;
    }
  }
}

html body .flexslider .slides img{
  height: auto;
}

.blogcontentmain .post .post_thumbnail{
  display: none;
}

.aligncenter{
  text-align:center;
  margin:auto;
}

.isc-source.aligncenter{
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  .isc-source-text{
    left: 50%;
  }
}

#gallery-1 .gallery-item{
  @include media-breakpoint-down(md){
    width: 100% !important;
  }
  img{
    width: 100%;
    height: auto;
  }
}

.youtube-iframe-placeholder{
  a{
    color: #ffffff;
    text-decoration: underline;
  }
}